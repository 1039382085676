<script setup lang="ts">
import { format } from 'date-fns'
import { computed } from 'vue'

const { date } = defineProps<{ date: Date }>()

const selectedMonthYear = computed(() => format(date, 'MMMM yyyy'))
const selectedWeek = computed(() => format(date, 'w'))
</script>

<template>
    <header class="flex flex-none flex-wrap items-center justify-between gap-4 pb-4">
        <slot name="filters"></slot>
        <div class="flex w-full flex-wrap justify-between gap-3 md:flex-row md:items-center md:gap-6 lg:w-fit lg:flex-nowrap">
            <h1 class="text-xl font-semibold leading-7 text-zinc-800">
                <time :datetime="date.toDateString()">
                    {{ selectedMonthYear }} -
                    {{ $t('calendar.week') }}
                    {{ selectedWeek }}</time
                >
            </h1>
            <slot />
        </div>
    </header>
</template>
