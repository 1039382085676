<script setup lang="ts">
import { useLocale } from '@app/composables/useLocale'
import type { ShiftMemberIssue } from '@app/types/shifts'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/16/solid'

defineProps<{ issue: ShiftMemberIssue }>()

const { translate } = useLocale()
</script>

<template>
    <div>
        <div class="relative px-1">
            <div class="flex size-5 items-center justify-center">
                <CheckCircleIcon v-if="issue.resolved_at" class="text-green-600" />
                <ExclamationCircleIcon v-else class="text-red-500" />
            </div>
        </div>
    </div>
    <div class="min-w-0 flex-1">
        <div class="text-sm text-zinc-800">{{ translate(issue.issue_type.name) }}</div>
        <div class="mt-2 text-sm text-zinc-500">
            <p>
                <slot></slot>
            </p>
        </div>
    </div>
</template>
