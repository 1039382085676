<script setup lang="ts">
import DeleteShift from '@app/components/shifts/shifts/actions/DeleteShift.vue'
import DetailCard from '@app/components/ui/card/DetailCard.vue'
import DetailCardLabel from '@app/components/ui/card/DetailCardLabel.vue'
import DetailCardTab from '@app/components/ui/card/DetailCardTab.vue'
import DetailCardTitle from '@app/components/ui/card/DetailCardTitle.vue'
import type { Shift } from '@app/types/shifts'
import { getDateFromDateTime, getTimeFromDateTime } from '@app/utils/date'
import { DocumentTextIcon, UserGroupIcon } from '@heroicons/vue/20/solid'
import ShiftDetails from './ShiftDetails.vue'
import ShiftMembers from './ShiftMembers.vue'
import EditShift from './actions/EditShift.vue'
import MarkShiftMemberSick from './actions/MarkShiftMemberSick.vue'
import RefreshBriggsWalkerShift from './actions/RefreshBriggsWalkerShift.vue'

const { shift } = defineProps<{
    shift: Shift
}>()

const emit = defineEmits(['close'])
</script>

<template>
    <DetailCard :model="shift" :dismissable="true" @close="emit('close')">
        <div class="flex w-full flex-col items-center justify-between gap-4">
            <DetailCardTitle class="w-auto">{{ $t('attributes.shift') }}</DetailCardTitle>
            <div class="flex flex-col items-start gap-4">
                <DetailCardLabel :label="$t('attributes.client_project') + ' :'">
                    <span class="text-sm font-semibold uppercase leading-6"> {{ shift.clientProject.name }} </span>
                </DetailCardLabel>
                <DetailCardLabel :label="$t('attributes.branch') + ' :'">
                    <span class="text-sm font-semibold uppercase leading-6"> {{ shift.branch.name }} </span>
                </DetailCardLabel>
                <DetailCardLabel :label="$t('attributes.date') + ' :'">
                    <span class="text-sm font-semibold uppercase leading-6"> {{ getDateFromDateTime(shift.start) }} </span>
                </DetailCardLabel>
                <DetailCardLabel :label="$t('attributes.time') + ' :'">
                    <span class="text-sm font-semibold uppercase leading-6">
                        {{ getTimeFromDateTime(shift.start) + ' - ' + getTimeFromDateTime(shift.end) }}
                    </span>
                </DetailCardLabel>
            </div>
        </div>
        <template #actions>
            <RefreshBriggsWalkerShift :shift />
            <EditShift :shift />
            <MarkShiftMemberSick :shift />
            <DeleteShift :shift />
        </template>
        <template #tabs>
            <DetailCardTab tab="shift_members" :label="$t('shifts.members')">
                <template #icon>
                    <UserGroupIcon />
                </template>
                <ShiftMembers :shiftMembers="shift.shiftMembers" />
            </DetailCardTab>
            <DetailCardTab tab="details" label="Details">
                <template #icon>
                    <DocumentTextIcon />
                </template>
                <ShiftDetails :shift="shift" />
            </DetailCardTab>
        </template>
    </DetailCard>
</template>
