<script setup lang="ts">
import Alert from '@app/components/ui/alert/Alert.vue'
import CancelButton from '@app/components/ui/button/CancelButton.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import ModalTitle from '@app/components/ui/calendar/modal/ModalTitle.vue'
import Label from '@app/components/ui/dropdown/Label.vue'
import FieldError from '@app/components/ui/form/FieldError.vue'
import Select from '@app/components/ui/form/select/Select.vue'
import SelectOption from '@app/components/ui/form/select/SelectOption.vue'
import { useLocale } from '@app/composables/useLocale'
import type { PageProps } from '@app/types/inertia'
import { type User } from '@app/types/shared'
import type { ShiftHour, ShiftTemplate } from '@app/types/shifts'
import { getDateFromDateTime } from '@app/utils/date'
import { translatedSelectPlaceholder } from '@app/utils/form'
import { getUniqueModelsFromArray, sortObjectByKey } from '@app/utils/object'
import { ucfirst } from '@app/utils/transform'
import { ArrowsPointingInIcon, CalendarIcon, ClockIcon, MapIcon, PaperClipIcon } from '@heroicons/vue/20/solid'
import { useForm, usePage } from '@inertiajs/vue3'
import { eachDayOfInterval, endOfWeek, format, startOfWeek } from 'date-fns'
import { trans } from 'laravel-vue-i18n'
import { isEmpty, uniqBy } from 'lodash'
import { computed, type ComputedRef } from 'vue'
import { route } from 'ziggy-js'

const { date, userShiftTemplates } = defineProps<{
    date: Date
    userShiftTemplates: ShiftTemplate[]
}>()

const user = computed(() => usePage<PageProps>().props.user)

const availabilityIsEnabled = computed(() => userShiftTemplates.length > 0)

const clientProjects = computed(() => getUniqueModelsFromArray(userShiftTemplates, 'client_project'))

const selectableLocationTypes = computed(() => {
    let templates = userShiftTemplates.filter((shiftTemplate) => shiftTemplate.client_project_id === form.client_project_id)

    return getUniqueModelsFromArray(templates, 'location_type')
})

const selectableBranches = computed(() => {
    let templates = userShiftTemplates.filter(
        (shiftTemplate) => shiftTemplate.client_project_id === form.client_project_id && shiftTemplate.location_type_id === form.location_type_id
    )

    return getUniqueModelsFromArray(templates, 'branch')
})

const selectableShiftHours = computed(() => {
    let shiftHours = userShiftTemplates
        .filter(
            (shiftTemplate) =>
                shiftTemplate.client_project_id === form.client_project_id &&
                shiftTemplate.location_type_id === form.location_type_id &&
                shiftTemplate.branch_id === form.branch_id
        )
        .reduce((acc, shiftTemplate) => {
            return [...acc, ...shiftTemplate.shift_hours]
        }, [] as ShiftHour[])

    return uniqBy(shiftHours, 'id')
}) as ComputedRef<ShiftHour[]>

const selectedWeek = computed(() => format(date, 'w'))
const days = computed(() =>
    eachDayOfInterval({
        start: startOfWeek(date, { weekStartsOn: 1 }),
        end: endOfWeek(date, { weekStartsOn: 1 }),
    })
)

type FormModel = {
    user: User | null
    client_project_id: number | null
    location_type_id: number | null
    branch_id: number | null
    shift_availabilities: Record<string, { date: string; shift_hour_id: number | null }>
}

const form = useForm<FormModel>({
    user: user.value,
    client_project_id: user.value.client_project_id,
    location_type_id: null,
    branch_id: user.value.branch_id,
    shift_availabilities: {},
})

function toggleSelectedDay(day: Date) {
    const key = day.toDateString()

    if (form.shift_availabilities[key]) {
        delete form.shift_availabilities[key]
    } else {
        const defaultShiftHours = selectableShiftHours.value.find((item) => item.is_default)

        form.shift_availabilities[key] = {
            date: key,
            shift_hour_id: defaultShiftHours?.id ?? null,
        }

        form.shift_availabilities = sortObjectByKey(form.shift_availabilities, 'date')
    }
}

const { getLocale } = useLocale()

const emit = defineEmits(['close'])

const submit = () => {
    form.post(route('shifts.availability.store'), {
        preserveScroll: true,
        onSuccess: () => emit('close'),
        onError: () => {
            let keys = Object.entries(form.errors)

            keys.forEach(([key, message]) => {
                if (key.startsWith('shift_availabilities.')) {
                    const [_, index] = key.split('.')

                    if (!form.errors.shift_availabilities) {
                        form.errors.shift_availabilities = {}
                    }

                    form.errors.shift_availabilities[index] = message
                }
            })
        },
    })
}
</script>

<template>
    <div class="w-[23rem] p-6 md:min-w-[32rem]">
        <div class="flex w-full flex-col items-start gap-3">
            <div class="flex gap-2">
                <div class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-primary-100">
                    <CalendarIcon class="h-6 w-6 text-primary-600" aria-hidden="true" />
                </div>
                <ModalTitle :label="trans('calendar.availability.add')" />
            </div>
            <div class="flex w-full flex-col gap-6 pl-4 md:pl-12">
                <p class="text-base font-medium leading-6 text-zinc-900">
                    {{ format(days[0], 'dd') }} - {{ getDateFromDateTime(days[days.length - 1]) }} ({{ $t('calendar.week') }} {{ selectedWeek }})
                </p>
                <Alert v-if="!availabilityIsEnabled" type="warning">
                    {{ $t('shifts.create_availability.missing_working_range') }}
                </Alert>

                <div v-else class="flex flex-col gap-6 pb-6 text-base font-medium leading-6 text-zinc-700">
                    <div class="flex flex-col gap-2">
                        <div class="flex items-center space-x-4">
                            <PaperClipIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />
                            <Select
                                class="w-2/3"
                                :border="false"
                                v-model="form.client_project_id"
                                :placeholder="translatedSelectPlaceholder('attributes.client_project')"
                                :disabled="clientProjects.length === 0"
                                autoselect
                            >
                                <SelectOption
                                    v-for="clientProject in clientProjects"
                                    :value="clientProject.id"
                                    :label="clientProject.name"
                                    :key="clientProject.id"
                                />
                            </Select>
                        </div>
                    </div>
                    <div v-if="selectableLocationTypes.length > 0" v-show="selectableLocationTypes.length > 1" class="flex flex-col gap-2">
                        <div class="flex items-center space-x-4">
                            <ArrowsPointingInIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />

                            <Select
                                class="w-2/3"
                                :border="false"
                                v-model="form.location_type_id"
                                :placeholder="translatedSelectPlaceholder('attributes.location_type')"
                                autoselect
                            >
                                <SelectOption
                                    v-for="locationType in selectableLocationTypes"
                                    :value="locationType.id"
                                    :label="locationType.name"
                                    :key="locationType.id"
                                />
                            </Select>
                        </div>
                    </div>
                    <div v-if="selectableBranches.length > 0" class="flex flex-col gap-2">
                        <div class="flex items-center space-x-4">
                            <MapIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />
                            <Select
                                class="w-2/3"
                                :border="false"
                                v-model="form.branch_id"
                                :placeholder="translatedSelectPlaceholder('attributes.branch')"
                                autoselect
                            >
                                <SelectOption v-for="branch in selectableBranches" :value="branch.id" :label="branch.name" :key="branch.id" />
                            </Select>
                        </div>
                    </div>
                </div>
                <div v-if="selectableShiftHours.length > 0" class="flex flex-col gap-4 border-t border-zinc-200 pb-6">
                    <Label :label="$t('form.select') + $t('recruitment.availabilities.days')" />
                    <div class="flex gap-3 text-xs leading-6 text-zinc-500">
                        <button
                            v-for="day in days"
                            @click="toggleSelectedDay(day)"
                            :class="[
                                form.shift_availabilities[day.toDateString()] ? 'bg-primary-500 text-white' : 'bg-zinc-100 text-zinc-500',
                                'flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-xs font-medium uppercase leading-4 tracking-wide',
                            ]"
                        >
                            {{
                                format(day, 'EEEEEE', {
                                    locale: getLocale(),
                                })
                            }}
                        </button>
                    </div>
                    <div v-for="(availability, index) in form.shift_availabilities">
                        <div class="text-sm leading-4 text-zinc-500">
                            {{ ucfirst(format(availability.date, 'EEEE', { locale: getLocale() })) }}
                            {{ format(availability.date, 'dd-MM-yyyy', { locale: getLocale() }) }}
                        </div>
                        <div class="mt-2 flex items-center space-x-4">
                            <ClockIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />
                            <Select
                                class="w-2/3"
                                v-model="form.shift_availabilities[index].shift_hour_id"
                                :placeholder="translatedSelectPlaceholder('attributes.time')"
                            >
                                <SelectOption
                                    v-for="shiftHours in selectableShiftHours"
                                    :value="shiftHours.id"
                                    :label="`${shiftHours.start_time} - ${shiftHours.end_time}`"
                                    :key="shiftHours.id"
                                />
                            </Select>
                        </div>
                        <FieldError
                            v-if="form.errors.shift_availabilities && index in form.errors.shift_availabilities"
                            :message="form.errors.shift_availabilities[index]"
                            class="mt-2"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="availabilityIsEnabled" class="flex flex-row-reverse gap-4 px-6 py-3">
        <SaveButton :disabled="isEmpty(form.shift_availabilities) || form.processing" @click="submit">{{ $t('calendar.availability.add') }}</SaveButton>
        <CancelButton @click="emit('close')" />
    </div>
</template>
