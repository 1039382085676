<script setup lang="ts">
import { usePage } from '@inertiajs/vue3'
import { computed, ref, watch } from 'vue'
import FloatingAlert from '../alert/FloatingAlert.vue'

type Toast = {
    message: string
    type: 'success' | 'information' | 'error' | 'warning'
}

const toast = computed(() => usePage().props.toast as Toast | null)
const showToast = ref(false)

watch(toast, () => {
    showToast.value = !!toast.value
})
</script>

<template>
    <FloatingAlert v-if="showToast" :as="toast?.type || 'information'" :timeout="10" @close="showToast = false">
        {{ toast?.message }}
    </FloatingAlert>
</template>
