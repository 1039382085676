<script setup lang="ts">
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline'
import { computed, onMounted } from 'vue'

const alertTypes = ['error', 'warning', 'information', 'success'] as const

const { as = 'information', timeout = 0 } = defineProps<{
    as?: (typeof alertTypes)[number]
    timeout?: number
}>()

const emit = defineEmits(['close'])

const close = () => {
    emit('close')
}

onMounted(() => {
    if (!timeout) {
        return
    }

    setTimeout(() => {
        close()
    }, timeout * 1000)
})

const backgroundColor = computed(() => {
    switch (as) {
        case 'error':
            return 'bg-red-50'
        case 'warning':
            return 'bg-yellow-50'
        case 'information':
            return 'bg-cyan-50'
        case 'success':
            return 'bg-green-50'
    }
})

const textColor = computed(() => {
    switch (as) {
        case 'error':
            return 'text-red-600'
        case 'warning':
            return 'text-yellow-600'
        case 'information':
            return 'text-cyan-600'
        case 'success':
            return 'text-green-600'
    }
})
</script>

<template>
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div aria-live="assertive" class="pointer-events-none fixed inset-0 z-10 flex items-end py-6 sm:items-start sm:py-16">
        <div class="flex w-full flex-col items-end space-y-4">
            <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
            <transition
                enter-active-class="transform ease-out duration-300 transition"
                enter-from-class="translate-x-full opacity-0"
                enter-to-class="translate-x-0 opacity-100"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="translate-x-0 opacity-100"
                leave-to-class="translate-x-full opacity-0"
            >
                <div @click="close" class="pointer-events-auto w-full max-w-sm cursor-default overflow-hidden rounded-l-lg shadow-md" :class="backgroundColor">
                    <div class="p-4">
                        <div class="flex items-start gap-2">
                            <div class="flex-shrink-0">
                                <XCircleIcon v-if="as === 'error'" class="h-6 w-6" :class="textColor" aria-hidden="true" />
                                <ExclamationCircleIcon v-if="as === 'warning'" class="h-6 w-6" :class="textColor" aria-hidden="true" />
                                <InformationCircleIcon v-if="as === 'information'" class="h-6 w-6" :class="textColor" aria-hidden="true" />
                                <CheckCircleIcon v-if="as === 'success'" class="h-6 w-6" :class="textColor" aria-hidden="true" />
                            </div>
                            <div class="w-0 flex-1 place-self-center">
                                <p class="text-sm font-medium" :class="textColor">
                                    <slot />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
