<script setup lang="ts">
import ConfirmAlert from '@app/components/ui/alert/ConfirmAlert.vue'
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useUserCan } from '@app/composables/useUserCan'
import type { Shift } from '@app/types/shifts'
import { TrashIcon } from '@heroicons/vue/24/solid'
import { router } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const { shift } = defineProps<{ shift: Shift }>()
const { can } = useUserCan()
const show = computed(() => can('shifts.shift.destroy'))

function deleteShift() {
    router.delete(route('shifts.shift.destroy', shift.id))
}
</script>

<template>
    <ConfirmAlert v-slot="{ confirm }" :title="$t('shifts.delete.confirm_title')" :text="$t('shifts.delete.confirm_text')" @confirmed="deleteShift">
        <RoundButton v-if="show" :label="$t('actions.delete')" @click="confirm" :disabled="!shift.deletable">
            <TrashIcon class="size-5" aria-hidden="true" />
        </RoundButton>
    </ConfirmAlert>
</template>
