<script setup lang="ts" generic="T extends { id: number }">
import { onUnmounted, provide, ref, shallowRef, watch } from 'vue'
import Card from './Card.vue'

const props = defineProps<{ model: T; dismissable?: boolean; activeTab?: string }>()

const emit = defineEmits(['close'])

const activeTab = ref(props.activeTab)
const activeTabComponent = shallowRef()

provide('tabs', { activeTab, activeTabComponent })

const activeButton = shallowRef()
const activeButtonProps = ref({})
const setActiveButton = (name: string | null) => {
    activeButton.value = name
}

provide('activeButton', activeButton)
provide('activeButtonProps', activeButtonProps)

onUnmounted(() => {
    setActiveButton(null)
})

watch(
    () => props.model.id,
    () => setActiveButton(null)
)
</script>

<template>
    <Card :dismissable="dismissable" @close="emit('close')" id="detail_card">
        <div class="flex w-full flex-col items-center justify-between" :key="`card-header-${model.id}`">
            <slot></slot>

            <div class="mt-6 flex flex-wrap items-start justify-between gap-6">
                <slot name="actions" v-bind="{ model, setActiveButton, activeButton }"></slot>
            </div>

            <div class="mx-auto mt-4 w-full items-center justify-between">
                <Transition name="fade" mode="out-in">
                    <component :is="activeButton" :model="model" v-bind="activeButtonProps" @content:closed="activeButton = null" />
                </Transition>
            </div>

            <slot name="progress-tracker"></slot>
        </div>
        <div v-if="$slots.tabs" class="block w-full" :key="`card-tabs-${model.id}`">
            <nav class="-mb-px flex w-full border-b border-gray-200" aria-label="Tabs">
                <slot name="tabs"></slot>
            </nav>

            <Transition name="fade" mode="out-in" :duration="200">
                <div :key="activeTab" class="min-h-[24px]">
                    <component :is="activeTabComponent" :model="model" />
                </div>
            </Transition>
        </div>
    </Card>
</template>
