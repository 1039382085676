<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useUserCan } from '@app/composables/useUserCan'
import type { ShiftAvailability } from '@app/types/shifts'
import { TrashIcon } from '@heroicons/vue/24/solid'
import { router } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const { availability } = defineProps<{ availability: ShiftAvailability }>()

const { can } = useUserCan()
const show = computed(() => can('shifts.availability.destroy'))

function deleteAvailability() {
    router.delete(route('shifts.availability.destroy', availability.id))
}
</script>

<template>
    <RoundButton v-if="show" :label="$t('actions.delete')" :disabled="!availability.can_be_deleted">
        <TrashIcon class="size-5" aria-hidden="true" @click="deleteAvailability" />
    </RoundButton>
</template>
