<script setup lang="ts">
import { computed, ref } from 'vue'

const layoutFractions = ['2/3', '3/4'] as const

const { fraction } = withDefaults(
    defineProps<{
        fraction?: null | (typeof layoutFractions)[number]
    }>(),
    {
        fraction: '3/4',
    }
)

const tableTransitioningClass = computed(() => {
    if (fraction === '3/4') {
        return 'hidden md:block md:!w-3/5 lg:!w-3/4'
    }
    if (fraction === '2/3') {
        return 'hidden md:block md:!w-3/5 lg:!w-2/3'
    }
})

const transitionClass = computed(() => {
    if (fraction === '3/4') {
        return 'w-full md:w-2/5 lg:w-1/4'
    }
    if (fraction === '2/3') {
        return 'w-full md:w-2/5 lg:w-1/3 '
    }
})

const isTransitioning = ref(false)

function onBeforeLeave() {
    isTransitioning.value = true
}

function onAfterLeave() {
    isTransitioning.value = false
}
</script>

<template>
    <div class="flex w-full items-start gap-2 lg:gap-4">
        <div class="table-transition" :class="isTransitioning || $slots.detail ? tableTransitioningClass : 'block w-full'">
            <slot />
        </div>

        <Transition name="slide-fade" mode="out-in" @before-leave="onBeforeLeave" @after-leave="onAfterLeave">
            <div v-if="$slots.detail" :class="transitionClass">
                <slot name="detail" />
            </div>
        </Transition>
    </div>
</template>
