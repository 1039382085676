<script setup lang="ts">
import ContentTabs from '@app/components/layout/ContentTabs.vue'
import TwoPaneLayout from '@app/components/layout/TwoPaneLayout.vue'
import AvailabilityCard from '@app/components/shifts/availabilities/AvailabilityCard.vue'
import CreateAvailabilityForm from '@app/components/shifts/availabilities/forms/CreateAvailabilityForm.vue'
import ShiftAvailabilityEvent from '@app/components/shifts/availabilities/ShiftAvailabilityEvent.vue'
import CreateIndividualShiftForm from '@app/components/shifts/shifts/forms/CreateIndividualShiftForm.vue'
import RefreshBriggsWalkerShiftsButton from '@app/components/shifts/shifts/RefreshBriggsWalkerShiftsButton.vue'
import ShiftCard from '@app/components/shifts/shifts/ShiftCard.vue'
import ShiftMemberCard from '@app/components/shifts/shifts/ShiftMemberCard.vue'
import ToggleButton from '@app/components/ui/button/ToggleButton.vue'
import Divider from '@app/components/ui/calendar/header/Divider.vue'
import Header from '@app/components/ui/calendar/header/Header.vue'
import WeekSwitcher from '@app/components/ui/calendar/header/WeekSwitcher.vue'
import Weekdays from '@app/components/ui/calendar/Weekdays.vue'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'
import ResetFilterButton from '@app/components/ui/table/filters/ResetFilterButton.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import UserNameAndAvatar from '@app/components/users/UserNameAndAvatar.vue'
import { useWeekSwitcher } from '@app/composables/calendar/useWeekSwitcher'
import { useNavigation } from '@app/composables/layout/useNavigation'
import { useChannel } from '@app/composables/useEcho'
import { useUserCan } from '@app/composables/useUserCan'
import type { Branch, ClientProject, LocationType, User, WorkType } from '@app/types/shared'
import type { Shift, ShiftAvailability, ShiftAvailabilityEvent as ShiftAvailabilityEventType, ShiftMember, ShiftTemplate } from '@app/types/shifts'
import { applyFilter } from '@app/utils/filter'
import { reloadPartial } from '@app/utils/inertia'
import { eachDayOfInterval, endOfWeek, isPast, startOfWeek } from 'date-fns'
import { computed, ref, watch } from 'vue'
import { route } from 'ziggy-js'

const { shiftAvailabilityTabs } = useNavigation()
const { can } = useUserCan()
const { subscribe } = useChannel('shift')

const { branches, locationTypes, users, clientProjects, selectedShift, selectedAvailability } = defineProps<{
    users: User[]
    branches: Branch[]
    locationTypes: LocationType[]
    clientProjects: ClientProject[]
    workTypes: WorkType[]
    userShiftTemplates: ShiftTemplate[]
    selectedShift: Shift | null
    selectedAvailability: ShiftAvailability | null
    selectedShiftMember: ShiftMember | null
}>()

const emit = defineEmits(['create-shift:close', 'close'])

const { selectedDay, switchWeek } = useWeekSwitcher({ weekParamName: 'week', reloadOnly: ['users'] })

const sortedDay = ref<Date | undefined>()

const formShown = ref<'shift' | 'availability'>()

const selectedAvailabilities = ref<ShiftAvailabilityEventType[]>([])

const weekdays = computed(() =>
    eachDayOfInterval({
        start: startOfWeek(selectedDay.value, { weekStartsOn: 1 }),
        end: endOfWeek(selectedDay.value, { weekStartsOn: 1 }),
    })
)

subscribe('.shift.member.marked-as-should-have-started', (event) => {
    if (event.work_type === 'contact-center') {
        reloadPartial(['users'])
    }
})

const sortUsers = (day: Date) => {
    sortedDay.value = day
    users.sort((a, b) => {
        const hasAvailabilityA = a.shift_availabilities.some((availability) => new Date(availability.from).toDateString() === day.toDateString())
        const hasAvailabilityB = b.shift_availabilities.some((availability) => new Date(availability.from).toDateString() === day.toDateString())

        return hasAvailabilityA === hasAvailabilityB ? 0 : hasAvailabilityA ? -1 : 1
    })
}

function handleAvailabilityClick(id: number) {
    if (selectedAvailability?.id === id) {
        closeCards()
    } else {
        applyFilter(
            { selected_shift: null, selected_availability: id, selected_shift_member: null },
            { only: ['selectedShift', 'selectedAvailability', 'selectedShiftMember'] }
        )
    }
}

function handleShiftClick(id: number) {
    if (selectedShift?.id === id) {
        closeCards()
    } else {
        applyFilter(
            { selected_shift: id, selected_availability: null, selected_shift_member: null },
            { only: ['selectedShift', 'selectedAvailability', 'selectedShiftMember'] }
        )
    }
}

function handleAvailabilitySelect(availability: ShiftAvailabilityEventType) {
    const index = selectedAvailabilities.value.findIndex((item) => item.id === availability.id)

    if (index !== -1) {
        selectedAvailabilities.value.splice(index, 1)
    } else {
        selectedAvailabilities.value.push(availability)
    }
}

const selectedDate = ref(new Date())
const selectedUser = ref()

const handleClick = (user: User, day: Date) => {
    if (formShown.value === 'shift') {
        return
    }

    const hasNoAvailability = !user.shift_availabilities.some((availability) => new Date(availability.from).toDateString() === day.toDateString())

    if (hasNoAvailability && !isPast(day.setHours(23, 59, 59))) {
        formShown.value = 'availability'
        selectedDate.value = day
        selectedUser.value = user
    }
}

function selectAllAvailability() {
    selectedAvailabilities.value = users.flatMap((user) => user.shift_availabilities.filter((availability) => !availability.shift_id))
}

function userAvailabilitiesForDay(user: User, day: Date) {
    return user.shift_availabilities.filter((item) => {
        return new Date(item.from).toDateString() === day.toDateString()
    })
}

function closeCards() {
    applyFilter(
        { selected_shift: null, selected_availability: null, selected_shift_member: null },
        { only: ['selectedShift', 'selectedAvailability', 'selectedShiftMember'] }
    )
}

watch(formShown, (newValue) => {
    if (newValue) {
        // Clear selected availability, shift and shift_member when a form is shown
        closeCards()
    }
})

const isPlanningEnabled = ref(false)

watch(isPlanningEnabled, (newValue) => {
    formShown.value = newValue ? 'shift' : undefined
})

function closeForm() {
    isPlanningEnabled.value = false
    selectedAvailabilities.value = []
    formShown.value = undefined
}
</script>

<template>
    <div>
        <ContentTabs bottomBorder :items="shiftAvailabilityTabs" />

        <div class="min-h-[24px]">
            <div class="flex items-start gap-4 scrollbar-hide">
                <div class="flex h-full w-full flex-col">
                    <Header :date="selectedDay" class="!items-end">
                        <template #filters>
                            <div class="flex flex-wrap items-end gap-4">
                                <FilterSection :showPerPageOptions="false" class="-mb-2 px-0">
                                    <DropdownMultiFilter border :items="clientProjects" :label="$t('attributes.client_project')" slug="client_project" />
                                    <DropdownMultiFilter
                                        v-if="route().params.workType !== 'contact-center'"
                                        border
                                        :items="locationTypes"
                                        :label="$t('attributes.location_type')"
                                        slug="location_type"
                                    />
                                    <DropdownMultiFilter border :items="branches" :label="$t('attributes.branch')" slug="branch" />
                                    <WeekSwitcher :model-value="selectedDay" @update:model-value="switchWeek" showLabel />
                                    <ResetFilterButton
                                        :href="route('shifts.planning.index', { workType: route().params.workType, week: route().params.week })"
                                        :label="$t('buttons.clear')"
                                    />
                                </FilterSection>
                            </div>
                        </template>
                        <div class="flex items-center gap-2">
                            <Divider v-if="['sales-agent', 'promotions'].includes(route().params.workType)" class="my-2" />
                            <RefreshBriggsWalkerShiftsButton v-if="['sales-agent', 'promotions'].includes(route().params.workType)" :only="['users']" />

                            <Divider v-if="can('shifts.shift.store')" class="" />
                            <div v-if="can('shifts.shift.store')" class="hidden items-center gap-2 text-sm font-medium text-zinc-800 md:flex">
                                <ToggleButton v-model="isPlanningEnabled" />Plan
                            </div>
                        </div>
                    </Header>
                    <TwoPaneLayout class="mt-2 overflow-hidden">
                        <div
                            ref="container"
                            class="isolate flex flex-auto flex-col overflow-y-hidden rounded-lg border-zinc-100 bg-white transition-width duration-100 ease-in-out scrollbar-hide sm:border"
                        >
                            <div class="flex h-full w-[300%] flex-none flex-col md:w-[200%] lg:w-full">
                                <div class="sticky top-0 z-50 flex-none pr-[11px] shadow-sm ring-1 ring-black ring-opacity-5">
                                    <div class="grid grid-cols-7 divide-x divide-zinc-100 border-b border-zinc-100 text-xs leading-4 text-zinc-500">
                                        <div class="left-0 col-end-1 w-36 border-r border-zinc-100 bg-white md:w-52" />
                                        <Weekdays :days="weekdays" :sortedDay="sortedDay" shorthand sortable @sort="sortUsers" />
                                    </div>
                                </div>
                                <div class="h-[calc(100vh-300px)] overflow-y-auto scrollbar-thin scrollbar-track-gray-100">
                                    <div class="flex">
                                        <table class="min-w-full table-fixed border-t-0">
                                            <tbody class="divide-y divide-zinc-100 bg-white">
                                                <tr
                                                    v-for="user in users"
                                                    class="row-span-4 grid min-h-16 w-full grid-cols-7 flex-col justify-between divide-x divide-zinc-100 text-sm"
                                                >
                                                    <td
                                                        class="sticky left-0 z-40 col-span-1 col-end-1 flex w-36 items-center whitespace-normal border-r border-zinc-100 bg-white px-4 md:w-52"
                                                    >
                                                        <UserNameAndAvatar :user="user" />
                                                    </td>
                                                    <td v-for="day in weekdays" class="flex max-h-full flex-col items-center overflow-hidden">
                                                        <div class="flex h-full w-full cursor-pointer flex-col gap-1" @click="handleClick(user, day)">
                                                            <ShiftAvailabilityEvent
                                                                v-for="availability in userAvailabilitiesForDay(user, day)"
                                                                :availability
                                                                :mode="formShown === 'shift' ? 'select' : 'normal'"
                                                                :selected="selectedAvailabilities.some((item) => item.id === availability.id)"
                                                                :disabled="!!availability.shift_id"
                                                                @select="handleAvailabilitySelect(availability)"
                                                                @availability:clicked="handleAvailabilityClick"
                                                                @shift:clicked="handleShiftClick"
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template #detail v-if="formShown === 'shift'">
                            <CreateIndividualShiftForm
                                v-model="selectedAvailabilities"
                                @toggle="handleAvailabilitySelect"
                                @select:all="selectAllAvailability"
                                @close="closeForm"
                            />
                        </template>
                        <template #detail v-else-if="formShown === 'availability'">
                            <CreateAvailabilityForm @close="closeForm" :date="selectedDate" :plannerShiftTemplates="userShiftTemplates" :user="selectedUser" />
                        </template>
                        <template #detail v-else-if="selectedAvailability">
                            <AvailabilityCard @close="handleAvailabilityClick(selectedAvailability.id)" :availability="selectedAvailability" />
                        </template>
                        <template #detail v-else-if="selectedShift">
                            <ShiftCard @close="handleShiftClick(selectedShift.id)" :shift="selectedShift" />
                        </template>
                        <template #detail v-else-if="selectedShiftMember">
                            <ShiftMemberCard :shift-member="selectedShiftMember" @close="closeCards()" />
                        </template>
                    </TwoPaneLayout>
                </div>
            </div>
        </div>
    </div>
</template>
