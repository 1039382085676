<script setup lang="ts">
import { DialogTitle } from '@headlessui/vue'
import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid'
import { ref } from 'vue'
import Button from '../button/Button.vue'
import ModalLayout from '../modal/ModalLayout.vue'

const emit = defineEmits(['confirmed'])

defineProps<{ title: string; text: string }>()

const confirmShown = ref(false)

function showConfirmationDialog() {
    confirmShown.value = true
}

function confirm() {
    emit('confirmed')
    confirmShown.value = false
}
</script>

<template>
    <slot v-bind="{ confirm: showConfirmationDialog }" />

    <ModalLayout :show="confirmShown" :dismissable="false">
        <div class="p-6">
            <div class="sm:flex sm:items-start">
                <div class="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10">
                    <ExclamationTriangleIcon class="size-5 text-red-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle as="h3" class="text-base font-semibold text-zinc-800">{{ title }}</DialogTitle>
                    <div class="mt-2">
                        <p class="text-sm text-zinc-500">
                            {{ text }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="mt-4 flex flex-row-reverse gap-2">
                <Button type="button" color="red" @click="confirm">{{ $t('buttons.confirm') }}</Button>
                <Button color="white" type="button" @click="confirmShown = false">
                    {{ $t('buttons.cancel') }}
                </Button>
            </div>
        </div>
    </ModalLayout>
</template>
