<script setup lang="ts">
import ConfirmAlert from '@app/components/ui/alert/ConfirmAlert.vue'
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useUserCan } from '@app/composables/useUserCan'
import type { Shift } from '@app/types/shifts'
import { FaceFrownIcon } from '@heroicons/vue/20/solid'
import { router } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const { shift } = defineProps<{ shift: Shift }>()

const { can } = useUserCan()

const showButton = computed(
    () =>
        can('shifts.member.sick') &&
        shift.shiftMembers.length === 1 &&
        shift.clientProject.work_type.slug === 'contact-center' &&
        shift.shiftMembers[0].can_be_marked_as_sick
)

const markAsSick = () => {
    router.post(
        route('shifts.member.mark-sick', shift.shiftMembers[0].id),
        {},
        {
            onSuccess() {
                close()
            },
        }
    )
}
</script>

<template>
    <ConfirmAlert
        v-slot="{ confirm }"
        :title="$t('shifts.sick.title')"
        :text="$t('shifts.sick.text', { name: shift.shiftMembers[0].shift_availability.userable.full_name })"
        @confirmed="markAsSick"
    >
        <RoundButton v-if="showButton" :label="$t('shifts.sick.sick')" :disabled="!shift.shiftMembers.length" @click="confirm">
            <FaceFrownIcon class="size-5" aria-hidden="true" />
        </RoundButton>
    </ConfirmAlert>
</template>
