<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import DismissButton from '@app/components/ui/button/DismissButton.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import Card from '@app/components/ui/card/Card.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FromToDate from '@app/components/ui/table/column/FromToDate.vue'
import type { ShiftAvailabilityEvent } from '@app/types/shifts'
import { router } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const selected = defineModel<ShiftAvailabilityEvent[]>({ default: [] })

const emit = defineEmits(['close', 'toggle', 'select:all'])

const selectedIds = computed(() => selected.value.map((item: ShiftAvailabilityEvent) => item.id))

function createShifts() {
    router.post(
        route('shifts.shift.create-individual'),
        {
            availabilities: selectedIds.value,
        },
        {
            onSuccess: () => emit('close'),
        }
    )
}
</script>

<template>
    <Card dismissable @close="emit('close')">
        <div class="mt-2 w-full space-y-4">
            <div class="text-left text-xl font-semibold leading-7 text-zinc-800">{{ $t('shifts.plan_shifts') }}</div>

            <div v-if="selectedIds.length === 0" class="text-gray-500">{{ $t('shifts.plan_shifts_description') }}</div>

            <div class="flex flex-wrap gap-2">
                <Button color="white" class="!whitespace-normal" size="xs" @click="$emit('select:all')">{{ $t('shifts.planning.select_all') }}</Button>
                <Button v-if="selectedIds.length > 0" color="white" size="xs" @click="selected = []">{{ $t('form.clear_selection') }}</Button>
            </div>

            <div class="space-y-4">
                <div v-for="availability in selected" class="relative flex justify-between rounded-lg border border-gray-200 px-4 py-2">
                    <div>
                        <p class="text-sm font-medium text-zinc-900">{{ availability.user_full_name }}</p>
                        <p class="mt-1 text-xs text-gray-500"><FromToDate :from="availability.from" :to="availability.to" /></p>
                        <p class="mt-1 text-xs text-gray-500">{{ availability.client_project_name }} - {{ availability.branch_name }}</p>
                    </div>

                    <DismissButton @click="$emit('toggle', availability)" />
                </div>
            </div>

            <FormActions>
                <SaveButton :disabled="selectedIds.length === 0" @click="createShifts" />
            </FormActions>
        </div>
    </Card>
</template>
