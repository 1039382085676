<script setup lang="ts">
import Badge from '@app/components/ui/badge/Badge.vue'
import { useColor } from '@app/composables/useColor'
import { useLocale } from '@app/composables/useLocale'
import type { ShiftMember } from '@app/types/shifts'
import { applyFilter } from '@app/utils/filter'
import { Link } from '@inertiajs/vue3'

defineProps<{
    shiftMembers: Array<ShiftMember>
}>()

const { dbTranslate } = useLocale()

function openShiftMemberCard(shiftMemberId: number) {
    applyFilter(
        {
            selected_shift: null,
            selected_availability: null,
            selected_shift_member: shiftMemberId,
        },
        { only: ['selectedShift', 'selectedShiftMember', 'selectedAvailability'] }
    )
}

const { getShiftMemberStatusColor } = useColor()
</script>

<template>
    <div class="flex w-full flex-col gap-4 py-4">
        <Link
            href=""
            as="button"
            v-for="member in shiftMembers"
            class="flex w-full rounded-md border border-zinc-100 py-4 pl-2 pr-4"
            @click="openShiftMemberCard(member.id)"
        >
            <div class="flex w-full flex-wrap items-center gap-2 overflow-hidden">
                <div class="flex items-center gap-3">
                    <div class="shrink-[999] rounded-full bg-primary-100 focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                        <img
                            class="inline-block size-8 rounded-full"
                            :src="member.shift_availability.userable.profile_photo_url"
                            :alt="member.shift_availability.userable.full_name"
                        />
                    </div>
                    <div class="text-left">
                        <p class="text-sm font-medium leading-5 text-zinc-800">{{ member.shift_availability.userable.full_name }}</p>
                        <p class="text-xs text-zinc-500">{{ dbTranslate(member.shift_availability.userable.job_title.name) }}</p>
                    </div>
                </div>
                <Badge size="sm" class="ml-auto" :label="dbTranslate(member.status.name)" :color="getShiftMemberStatusColor(member.status.slug)" />
            </div>
        </Link>
    </div>
</template>
