<script setup lang="ts">
import DetailCard from '@app/components/ui/card/DetailCard.vue'
import DetailCardLabel from '@app/components/ui/card/DetailCardLabel.vue'
import DetailCardTab from '@app/components/ui/card/DetailCardTab.vue'
import DetailCardTitle from '@app/components/ui/card/DetailCardTitle.vue'
import type { ShiftAvailability } from '@app/types/shifts'
import { DocumentTextIcon } from '@heroicons/vue/20/solid'
import DeleteAvailability from './actions/DeleteAvailability.vue'
import EditAvailability from './actions/EditAvailability.vue'
import AvailabilityDetails from './AvailabilityDetails.vue'

const { availability } = defineProps<{
    availability: ShiftAvailability
}>()

const emit = defineEmits(['close'])
</script>

<template>
    <DetailCard :model="availability" dismissable @close="emit('close')">
        <div class="flex flex-col items-center justify-between gap-4">
            <DetailCardTitle> {{ $t('attributes.availability') }}</DetailCardTitle>
            <div class="flex flex-col items-start gap-4">
                <DetailCardLabel :label="$t('attributes.name') + ' :'">
                    <span class="text-sm font-semibold uppercase leading-6"> {{ availability.user_full_name }} </span>
                </DetailCardLabel>
            </div>
        </div>
        <template #actions>
            <DeleteAvailability :availability />
            <EditAvailability :availability />
        </template>
        <template #tabs>
            <DetailCardTab tab="details" label="Details">
                <template #icon>
                    <DocumentTextIcon />
                </template>
                <AvailabilityDetails :availability="availability" />
            </DetailCardTab>
        </template>
    </DetailCard>
</template>
